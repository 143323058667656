exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asistencia-tecnica-index-js": () => import("./../../../src/pages/asistencia-tecnica/index.js" /* webpackChunkName: "component---src-pages-asistencia-tecnica-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-control-parental-index-js": () => import("./../../../src/pages/control-parental/index.js" /* webpackChunkName: "component---src-pages-control-parental-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-index-js": () => import("./../../../src/pages/internet/index.js" /* webpackChunkName: "component---src-pages-internet-index-js" */),
  "component---src-pages-megasoft-index-js": () => import("./../../../src/pages/megasoft/index.js" /* webpackChunkName: "component---src-pages-megasoft-index-js" */),
  "component---src-pages-productos-index-js": () => import("./../../../src/pages/productos/index.js" /* webpackChunkName: "component---src-pages-productos-index-js" */),
  "component---src-pages-seguridad-index-js": () => import("./../../../src/pages/seguridad/index.js" /* webpackChunkName: "component---src-pages-seguridad-index-js" */),
  "component---src-pages-sobre-nosotros-index-js": () => import("./../../../src/pages/sobre-nosotros/index.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-index-js" */)
}

